
const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://learn.pricingforpm.in/introduction',
    gaTrackingId: 'UA-155641151-3',
    trailingSlash: false,
  },
  header: {
    logo: 'https://bit.ly/380cNMo',
    logoLink: "https://learn.pricingforpm.in/introduction",
    title: "A to Z of pricing and monetisation",
    githubUrl: 'https://github.com/hasura/gatsby-gitbook-boilerplate',
    helpUrl: '',
    tweetText: '',
    social: ``,
    links: [{ text: '', link: '' }],
    search: {
      enabled: false,
      indexName: '',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    forcedNavOrder: [
      '/introduction',
      '/pricing-strategy',
      '/pricing-model', // add trailing slash if enabled above
      '/pricing-psychology',
      '/module-5',
      '/module-6',
      '/module-7',
      '/module-8',
    ],
    collapsedNav: [
      '/codeblock', // add trailing slash if enabled above
    ],
    links: [],
    frontline: false,
    ignoreIndex: true,
    title:
      "",
  },
  siteMetadata: {
    title: 'Learn Pricing For Product Manager',
    description: 'API knowledge is a must for product managers. Excel your career growth and be a product leader.',
    ogImage: null,
    docsLocation: '/',
    favicon: '/',
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'Gatsby Gitbook Starter',
      short_name: 'GitbookStarter',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.png',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
};

module.exports = config;
