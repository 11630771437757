export const UNLOCKED_CHAPTERS = ['/', '/introduction', '/pricing-strategy/4-price-skimming', '/pricing-model/1-fremium-models', '/pricing-psychology/1-decoy-pricing'];

export const GUMROAD_PRODUCT_KEY = 'pricingforpm';

export const GUMROAD_PRODUCT_LINK = 'https://dipakkr.gumroad.com/l/pricingforpm';

export const LS_KEYS = {
  LICENSE_KEY: 'license-token',
  USER_EMAIL: 'user-email',
};

// Feature flags

export const TEST_MODE = true;

export const HAS_LOGIN_FEATURE = true;

export const HEADER_CONFIG = {
  CONTACT_INFO : {
    isEnabled : false,
    URL : ''
  }
}

export const LEAD_FORM_CONFIG = {
  isEnabled: true,
  API_URL: '',
};



export const THEMES_DATA = {
  HEADER_COLOR_CODE: '#001934',
  FOOTER_COLOR_CODE: '#001934',
};

export const PRODUCT_HUNT_CONFIG = {
  isEnabled: false,
  FEATURE_LINK: (
    <a
      href="https://www.producthunt.com/posts/api-for-product-managers?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-api-for-product-managers"
      target="_blank"
      className="btn"
    >
      <img
        src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=314397&theme=light&period=daily"
        alt="API for Product Managers - The A to Z of APIs for product managers | Product Hunt"
        style={{ width: '250px', height: '54px' }}
        width="250"
        height="54"
      />
    </a>
  ),
};

export const FEEDBACK_FORM_CONFIG = {
  isEnabled: true,
  URL: 'https://bit.ly/3Ag0Ldy',
};


export const FOOTER_CONFIG = {
  CONTACT_LINK : 'https://twitter.com/HQdeepak',
  SHOW_HOST_COURSE_LINK : true
}
