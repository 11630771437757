import { css } from '@emotion/react';

const customStyles = css`

.img-60{
max-width:60%;
}

.img-70{
    max-width:70%
}

.img-80{
    max-width:80%; 
}

.img-90{
    max-width : 90%;
}

.img-40{
    max-width : 40%;
    margin : 0 auto;
}

.img-center{
    margin:0 auto;
    display:flex;
    justify-content:center;
}

`;

export const styles = [customStyles];
